<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          Ürün Bazında Karlılık Tablosu
        </b-card-title>
        <b-card-sub-title />
      </div>
      <!--/ title and subtitle -->

      <!-- badge -->
      <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
        <h5 class="font-weight-bolder mb-0 mr-1" />

      </div>
      <!--/ badge -->
    </b-card-header>

    <b-card-body>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
              @input="handleSearch"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        mode="remote"
        :columns="columns"
        :rows="rows"
        :is-loading.sync="isLoading"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        @on-sort-change="onSortChange"
      >

        <template
          slot="table-row"
          slot-scope="props"
        >

          <span v-if="props.column.field == 'earning'">
            <span>%{{ parseFloat(props.row.earning).toFixed(2) }}</span>
          </span>
          <span v-else-if="props.column.field == 'total_sell'">
            <span>{{ $staticParams.formatCurrency(props.row.total_sell,2) }}</span>
          </span>
          <span v-else-if="props.column.field == 'total_buy'">
            <span>{{ $staticParams.formatCurrency(props.row.total_buy,2) }}</span>
          </span>
          <span v-else-if="props.column.field == 'count'">
            <span>{{ $staticParams.formatCurrency(props.row.count,0) }}</span>
          </span>
          <span v-else-if="props.column.field == 'hareket_tutar'">
            <span>{{ $staticParams.formatCurrency(props.row.hareket_tutar) }}</span>
          </span>
          <span
            v-else
          >
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="mx-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> of {{ totalRows }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="totalRows"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BPagination, BFormGroup, BFormInput, BFormSelect,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'

export default {
  components: {
    BCardHeader,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    VueGoodTable,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    filterOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localFilterOptions: null,
      pageLength: 20,
      dir: false,
      isLoading: false,
      sort: 'desc',
      totalRows: 0,
      order_column: 'earning',
      pages: ['10', '20', '30', '50'],
      page: 1,
      columns: [
        {
          label: 'Ürün',
          field: 'product_name',
        },
        {
          label: 'Adet',
          field: 'piece',
        },
        {
          label: 'Satıcı',
          field: 'seller_name',
        },
        {
          label: 'Alıcı',
          field: 'buyer',
        },
        {
          label: 'Alım Zamanı',
          field: 'buy_date',
        },
        {
          label: 'Satım Zamanı',
          field: 'sell_date',
        },
        {
          label: 'Alış Döviz Kuru',
          field: 'buy_exchange_rate',
        },
        {
          label: 'Alış Döviz',
          field: 'buy_currency_name',
        },
        {
          label: 'Satış Döviz Kuru',
          field: 'sell_exchange_rate',
        },
        {
          label: 'Satış Döviz',
          field: 'sell_currency_name',
        },
        {
          label: 'Alış Fiyatı Tl',
          field: 'buy_price',
        },
        {
          label: 'Satış Fiyatı Tl',
          field: 'sell_price',
        },
        {
          label: 'Karlılık',
          field: 'earning',
        },

      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {

  },
  watch: {
    userid() {
      this.fetchData()
    },
    filterOptions() {
      this.localFilterOptions = JSON.parse(JSON.stringify(this.filterOptions))
      this.fetchData()
    },
  },
  created() {
    this.localFilterOptions = JSON.parse(JSON.stringify(this.filterOptions))
    this.fetchData()
  },
  methods: {
    fetchData() {
      store
        .dispatch('app-reports/fetchProductSalesDetail', {
          user_id: this.userid,
          ordering: this.sort,
          data_count: this.pageLength,
          search_term: this.searchTerm,
          order_column: this.order_column,
          page: this.page,
          start_year: this.localFilterOptions.start_year,
          start_month: this.localFilterOptions.start_month,
          end_year: this.localFilterOptions.end_year,
          end_month: this.localFilterOptions.end_month,
          user_code: this.localFilterOptions.userCode,
          location: this.localFilterOptions.location,
          buyer_name: this.localFilterOptions.buyer_name,
          seller_code: this.localFilterOptions.sellerCode,
          product_code: this.localFilterOptions.product_stock_code,
          seller_codes: this.localFilterOptions.seller_codes,
          category: this.localFilterOptions.category,
          other_category: this.localFilterOptions.other_category,
        })
        .then(response => {
          const { data } = response.data.data
          const { meta } = response.data
          this.totalRows = meta.total
          this.rows = data
        })
        .catch(() => {
        })
    },
    // eslint-disable-next-line no-unused-vars
    handleSearch(searching) {
      this.fetchData()
    },
    handleChangePage(page) {
      this.page = page
      this.fetchData()
    },
    // eslint-disable-next-line no-unused-vars
    handlePageChange(active) {
      this.fetchData()
    },
    onSortChange(params) {
      this.order_column = params[0].field
      this.sort = params[0].type
      this.fetchData()
    },
  },

}
</script>
