<template>
  <div>

    <b-card no-body>
      <b-card-header>
        <!-- title and subtitle -->
        <div>
          <b-card-title class="mb-1">
            Aylık Karlılık Tablosu
          </b-card-title>
          <b-card-sub-title />
        </div>
        <!--/ title and subtitle -->

        <!-- badge -->
        <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
          <h5 class="font-weight-bolder mb-0 mr-1" />

        </div>
      <!--/ badge -->
      </b-card-header>

      <b-card-body>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
                @input="handleSearch"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :is-loading.sync="isLoading"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"

          @on-sort-change="onSortChange"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field == 'actions'" />
            <span v-else-if="props.column.field == 'month'">
              <span>{{ $staticParams.getMonthName(props.row.month) }}</span>
            </span>
            <span v-else-if="props.column.field == 'total_buy'">
              <span>{{ $staticParams.formatCurrency(props.row.total_buy ) }}</span>
            </span>
            <span v-else-if="props.column.field == 'total_sell'">
              <span>{{ $staticParams.formatCurrency(props.row.total_sell ) }}</span>
            </span>
            <span v-else-if="props.column.field == 'total_earning'">
              <span>{{ $staticParams.formatCurrency(props.row.total_earning ) }}</span>
            </span>
            <span v-else-if="props.column.field == 'earning_rate'">
              <span v-if="props.row.earning_rate!=null">%{{ parseFloat(props.row.earning_rate).toFixed(2) }}</span>
              <span v-else>VERİ MEVCUT DEĞİL</span>
            </span>
            <span
              v-else
            >
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

        </vue-good-table>
      </b-card-body>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'

export default {
  components: {
    BCardHeader,
    BFormGroup,
    BFormInput,
    VueGoodTable,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    filterOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localFilterOptions: null,
      pageLength: 20,
      dir: false,
      isLoading: false,
      sort: 'desc',
      totalRows: 0,
      order_column: 'earning',
      page: 1,
      columns: [
        {
          label: 'Yıl',
          field: 'year',
        },
        {
          label: 'Ay',
          field: 'month',
        },
        {
          label: 'Toplam Alış',
          field: 'total_buy',
        },
        {
          label: 'Toplam Satış',
          field: 'total_sell',
        },
        {
          label: 'Toplam Kazanç',
          field: 'total_earning',
        },
        {
          label: 'Karlılık Oranı',
          field: 'earning_rate',
        },

      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {

  },
  watch: {
    userid() {
      this.fetchData()
    },
    filterOptions() {
      this.localFilterOptions = JSON.parse(JSON.stringify(this.filterOptions))
      this.fetchData()
    },
  },
  created() {
    this.localFilterOptions = JSON.parse(JSON.stringify(this.filterOptions))
    this.fetchData()
  },
  methods: {
    showDetails(rowData) {
      this.localFilterOptions.location = rowData.location
      this.$refs['details-modal'].show()
    },
    fetchData() {
      store
        .dispatch('app-reports/fetchMonthlyEarningTable', {
          user_id: this.userid,
          ordering: this.sort,
          data_count: this.pageLength,
          search_term: this.searchTerm,
          order_column: this.order_column,
          page: this.page,
          start_year: this.localFilterOptions.start_year,
          start_month: this.localFilterOptions.start_month,
          end_year: this.localFilterOptions.end_year,
          end_month: this.localFilterOptions.end_month,
          user_code: this.localFilterOptions.seller_code,
          seller_code: this.localFilterOptions.seller_code,
          seller_codes: this.localFilterOptions.seller_codes,
          location: this.localFilterOptions.location,
          buyer_name: this.localFilterOptions.buyer_name,
          product_code: this.localFilterOptions.product_code,
          category: this.localFilterOptions.category,
          other_category: this.localFilterOptions.other_category,
        })
        .then(response => {
          const { data } = response.data
          this.rows = data
        })
        .catch(() => {
        })
    },
    // eslint-disable-next-line no-unused-vars
    handleSearch(searching) {
      this.fetchData()
    },
    handleChangePage(page) {
      this.page = page
      this.fetchData()
    },
    // eslint-disable-next-line no-unused-vars
    handlePageChange(active) {
      this.fetchData()
    },
    onSortChange(params) {
      this.order_column = params[0].field
      this.sort = params[0].type
      this.fetchData()
    },
  },

}
</script>
