<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          {{ title }}
        </b-card-title>
        <b-card-sub-title>{{ data.start_date+' - '+data.end_date }}Tarihleri Arası Temsilci Satış Grafiği</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

      <!-- badge -->
      <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
        <h5 class="font-weight-bolder mb-0 mr-1" />

      </div>
      <!--/ badge -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        v-if="chartData"
        type="line"
        height="400"
        :options="chartData.chartOptions"
        :series="chartData.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import store from '@/store'

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    filterOptions: {
      type: Object,
      required: true,
    },
    userCode: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      colors: [],
      chartData: null,
    }
  },
  computed: {

  },
  watch: {
    chartData(val) {
      if (val != null) {
        this.makeChartData()
      }
    },
  },
  created() {
    this.fetchChartData()
  },
  methods: {
    fetchChartData() {
      store
        .dispatch('app-reports/fetchSalesChart', {
          user_id: this.userid,
          ordering: this.sort,
          data_count: this.pageLength,
          search_term: this.searchTerm,
          order_column: this.order_column,
          page: this.page,
          start_year: this.filterOptions.start_year,
          start_month: this.filterOptions.start_month,
          end_year: this.filterOptions.end_year,
          end_month: this.filterOptions.end_month,
          user_code: this.userCode,
        })
        .then(response => {
          this.chartData = response.data.data
        })
        .catch(() => {
        })
    },
    makeChartData() {
      const seriesData = [
      ]
      const CategoriesData = []
      const Ccolors = []

      // eslint-disable-next-line no-unused-vars
      const currencyName = this.data.currency_name
      this.data.sales_data.forEach(val => {
        CategoriesData.push(val.doc_date)
        seriesData.push({ name: 'Karlılık', data: [val.earning] })
        seriesData.push({ name: 'Toplam Alış', data: [val.total_buy] })
        seriesData.push({ name: 'Toplam Satış', data: [val.total_sell] })
      })
      if (this.colors.length <= 0) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.colors = Ccolors
      }
      const cData = {

        series: seriesData,
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            horizontalAlign: 'center',
            fontFamily: 'Montserrat',
          },
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: true,
            },
          },
          markers: {
            strokeWidth: 10,
            strokeOpacity: 1,
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            curve: 'straight',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {

          },
          xaxis: {
            categories: CategoriesData,
          },
          yaxis: {
          // opposite: isRtl,
          },
        },
      }
      this.chartData = cData
    },
    getRandomColor() {
      const letters = '0123456789ABCDEF'
      let color = '#'
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    },
  },
}
</script>
